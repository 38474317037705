<template>
  <div class="setting">
    <navbar title="Configurar senha de pagamento"></navbar>
    <main>
      <div class="input1">
        <div class="input1-1">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <span>Por favor insira a senha de pagamento</span>
            <img
                class="small-button"
                @click="hidePassword = !hidePassword"
                :src="
              require(`@/assets/images/other/eye_${
                hidePassword ? 'hide' : 'open'
              }.png`)
            "
            />
          </div>
          <div class="password">
            <div class="between" style="padding: 0.1rem 0;"></div>
            <div v-if="!isPC">
              <van-password-input
                  v-model="firstPWD"
                  :focused="showKeyboard"
                  :mask="hidePassword"
                  @focus="showKeyboard = true"
              />
              <van-number-keyboard
                  v-model="firstPWD"
                  :show="showKeyboard"
                  @blur="showKeyboard = false"
              />
            </div>
            <div v-else>
              <van-password-input
                  v-model="firstPWD"
                  :mask="hidePassword"
              />
              <input type="text" class="inputNum" maxlength="6" @input="validateInput" v-model="firstPWD">
            </div>
          </div>
        </div>
        <div class="input1-1">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <span>Por favor insira a senha de pagamento novamente</span>
            <img
                class="small-button"
                @click="hidePassword2 = !hidePassword2"
                :src="
              require(`@/assets/images/other/eye_${
                hidePassword2 ? 'hide' : 'open'
              }.png`)
            "
            />
          </div>
          <div class="password">
            <div class="between" style="padding: 0.1rem 0;">
            </div>
            <div v-if="!isPC">
              <van-password-input
                  v-model="secondPWD"
                  :focused="showKeyboard2"
                  :mask="hidePassword2"
                  @focus="showKeyboard2 = true"
              />
              <van-number-keyboard
                  v-model="secondPWD"
                  :show="showKeyboard2"
                  @blur="showKeyboard2 = false"
              />
            </div>
            <div v-else>
              <van-password-input
                  v-model="secondPWD"
                  :mask="hidePassword2"
              />
              <input type="text" class="inputNum" maxlength="6" @input="validateInput" v-model="secondPWD">
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="left" @click="$router.back()">Retornar</button>
        <button class="right" @click="handleSubmit()">Salvar</button>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import {setPayPassword} from "@/api";
export default {
  components: { Navbar },
  data() {
    return {
      firstPWD:'',
      hidePassword: true,
      showKeyboard: false,
      secondPWD:'',
      hidePassword2: true,
      showKeyboard2: false,
      isPC:'',
    }
  },
  // 在组件的created钩子中使用判断结果
  created() {
    if (this.isAppEndpoint()) {
      this.isPC = false;
      console.log('App端');
    } else {
      this.isPC = true;
      console.log('PC端');
    }
  },
  mounted() {
    console.log(this.firstPWD.length)
    console.log(this.secondPWD)
  },
  methods: {
    async handleSubmit() {
      if (!this.firstPWD){
        this.$toast.fail("Defina uma senha para o primeiro pagamento");
        this.firstPWD = '';
        this.secondPWD = '';
        return;
      }
      if (!this.secondPWD){
        this.$toast.fail("Defina uma segunda senha de pagamento");
        this.firstPWD = '';
        this.secondPWD = '';
        return;
      }
      if (this.firstPWD.length!=6){
        this.$toast.fail("A senha que você definiu pela primeira vez está errada, redefina-a.");
        this.firstPWD = '';
        this.secondPWD = '';
        return;
      }
      if (this.secondPWD.length!=6){
        this.$toast.fail("A senha que você definiu pela segunda vez está errada, redefina-a.");
        this.firstPWD = '';
        this.secondPWD = '';
        return;
      }
      if (this.firstPWD !=this.secondPWD){
        this.$toast.fail("As duas configurações de senha são inconsistentes, redefina");
        this.firstPWD = '';
        this.secondPWD = '';
        return;
      }
      setPayPassword(this.firstPWD,this.secondPWD).then(res=>{
        this.$toast.success("Configuração bem sucedida")
        this.$router.push('/home/withdraw')
      })
    },
    validateInput() {
      // 只允许输入阿拉伯数字
      this.firstPWD = this.firstPWD.replace(/\D/g, '');
      this.secondPWD = this.secondPWD.replace(/\D/g, '');
    },
    isAppEndpoint() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 这里的正则表达式可以根据实际情况进行调整
      return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.ant-input[disabled]) {
  background: #303030;
  color: white;
}

::v-deep(.ant-input) {
  color: white;
}
.setting {
  main {
    padding: 0.1rem 0.1rem 0;
    background-color: #303030;
    height: calc(100vh - 50px);
    .input1{
      .input1-1{
        display: flex;
        flex-direction: column;
        padding: 10px;
        span{
          margin:5px 0;
        }
        .password {
          border-top: 1px solid #505050;
          .confirm_btn {
            margin-top: 0.3rem;
            width: 100%;
            padding: 0.1rem 0;
            color: black;
            // cursor: not-allowed;

            &:hover {
              background: #777777;
            }
          }
        }
      }

    }
    .btns {
      display: flex;
      gap: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.8242rem;
      padding: 0 0.1rem;
      background-color: var(--dark-bg);

      button {
        flex: 1;
        margin-top: 0.1rem;
        height: 0.4364rem;
      }
      .left {
        border: 1px solid var(--yellow);
        background: transparent;
        color: var(--yellow);
      }
      .right {
        color: black;
        background-color: var(--yellow);
      }
    }
    @media screen and (min-width: 751px) {
      .btns {
        position: fixed;
        width: 435px !important;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .inputNum{
    background: #1c1c1c00;
    border: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff00;
    margin-top: -50px;
    cursor: pointer;
    width: 381px;
    height: 50px;

  }
  .inputNum::selection {
    color: #ffffff00; /* 修改选中文字的颜色 */
    background-color: #1c1c1c00; /* 修改选中文字的背景颜色 */
  }
  ::v-deep(.van-password-input__item) {
    background: #1c1c1c;
    border-right: 1px solid #505050;
    //   cursor: not-allowed;
  }

  ::v-deep(.van-password-input) {
    i {
      background: #fff;
    }
  }
  ::v-deep(.van-number-keyboard) {
    left: 50%;
    width: 435px;
    transform: translateX(-50%);
  }
}
</style>
